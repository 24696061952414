<template>
  <div class="about">
    <div class="about__tip">
      <Tip back="/" andHideOverlay=true skip="#/ar-view,#/prize" />
    </div>
    <div class="about__content">
      <h1 class="about__headline">Der große Gewinn!</h1>
      <div class="about__description">
        <p>Scanne den QR-Code am Fenster des neuen H&M HOME Concept Stores und gewinne mit etwas Glück Geschenkkarten im Wert von bis zu 1000&nbsp;€</p>
      </div>
      <div class="about__prizes">
        <PrizesPreview />
      </div>
    </div>
    <div class="about__footer">
      <p>Mehr Informationen?</p>
      <Button to="/tutorial1" :onclick="makeVeryOverlay" value="So geht’s" />
    </div>
  </div>
</template>

<script>
import Tip from '@/components/Tip.vue'
import PrizesPreview from '@/components/PrizesPreview.vue'
import Button from '@/components/Button.vue'

export default {
  name: 'About',
  components: {
    Tip,
    PrizesPreview,
    Button
  },
  mounted(){
    ARController.hideVeryOverlay();
    store.set('currentPage', 'About');
  },
  methods: {
    makeVeryOverlay: function() {
      ARController.makeVeryOverlay();
    }
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.about {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $background-color;
}

.about__content {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 58px;
  padding: 24px 0 0;
  text-align: center;
  z-index: 1;
}

.about__headline {
  position: relative;
  margin: 0 0 15px;
  font-family: $accent-font;
  font-weight: 500;
  font-size: 27px;
  line-height: 1.1384;
  letter-spacing: 0.2px;
  z-index: 1;
}

.about__description {
  margin-bottom: 20px;
  font-size: 14px;
  @media (max-height: 636px) {
    font-size: 12px;
  }

  p {
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.about__prizes {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
}

.about__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  font-size: 14px;

  @media (max-height: 636px) {
    padding-top: 14px;
  }

  p {
    margin: 0 0 20px;
  }

  .button {
    min-width: 200px;
  }
}
</style>
