<template>
  <div class="prizes-preview">
    <ul>
      <li v-for="item in prizes" :key="item.message" :class="[item.value === '€1000' ? 'double' : '']">
        <div class="prize-preview" :onclick="logoclick" :style="{'background-color':item.color}">
          <div class="prize-preview__value">{{ item.value }}</div>
          <div class="prize-preview__logo">
            <LogoIcon />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import LogoIcon from '@/assets/images/logo.svg?inline'

export default {
  name: 'PrizesPreview',
  data () {
    return {
      prizes: [
        {
          value: '€1000',
          color: '#E3DBAF'
        },
        {
          value: '€50',
          color: '#D1E1D5'
        },
        {
          value: '€20',
          color: '#CED9E3'
        },
        {
          value: '€10',
          color: '#E3EBD9'
        },
        {
          value: '€5',
          color: '#EED3D3'
        }
      ]
    }
  },
  methods: {
    logoclick: async function() {
      //ARController.secret = await Ajax.async.post('/api/getSecret', {info: [navigator.userAgent, window.outerWidth,window.outerHeight, window.innerWidth, window.innerHeight]})
      //ARController.initSecret()
    }
  },
  components: {
    LogoIcon
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.prizes-preview {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: calc(100% - 46px);
  ul {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    width: calc(50% - 6px);
    //height: 100%;

    &.double {
      width: 100%;
    }
  }
}

.prize-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 10px 5px;
  width: 100%;
  height: 100%;
  text-align: center;
  color: $black;
  border-radius: 4px;
}

.prize-preview__value {
  margin-bottom: 14px;
  font-family: $accent-font;
  font-weight: 500;
  font-size: 9vw;
  line-height: 1.1384;
  letter-spacing: 0.2px;

  @media (max-height: 636px) {
    font-size: 5vh;
  }
}

.prize-preview__logo {
  width: calc(100% - 64px);
  min-width: 33px;
  height: auto;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}

.prizes-preview li.double {
  .prize-preview__logo {
    width: calc(100% - 170px);
  }
}
</style>
